import React, { useState } from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem
} from 'reactstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../../assets/images/NinjaDeveloper_Logo-Cores.svg';

const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	return (
		<div>
			<Navbar className="container" color="light" light expand="md" size="large" fixed="top">
				<NavbarBrand href="/">
					<img src={logo} alt="logo" style={{ height: 50 }} />
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar style={{ flexGrow: 1 }}>
					<Nav navbar>
						<NavItem>
							<AnchorLink href="#sites" className="nav-link" offset="100">Sites</AnchorLink>
						</NavItem>
						<NavItem>
							<AnchorLink href="#aplicativos" className="nav-link" offset="100">Aplicativos</AnchorLink>
						</NavItem>
						<NavItem>
							<AnchorLink href="#design" className="nav-link" offset="100">Design</AnchorLink>
						</NavItem>
						<NavItem>
							<AnchorLink href="#contato" className="nav-link" offset="100">Contato</AnchorLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default NavBar;