import React from 'react';
import close from '../../assets/images/close.svg';

const Lightbox = ({ image, label, closeLightbox }) => {
    closeLightbox = closeLightbox ? closeLightbox : () => {}
	return (
		<div className="lightbox-container" style={{display: image ? 'flex' : 'none'}}  onClick={() => closeLightbox('')} >
			<div className="lightbox-close-wrapper">
                <img src={close} className="lightbox-close" alt="Close" onClick={() => closeLightbox('')} />
            </div>
            <img 
                src={image} 
                className="lightbox-image"
                alt="lightbox"
            />
            <h4 className="lightbox-label">{label}</h4>
		</div>
	);
};

export default Lightbox;