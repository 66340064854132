// Components
import Cases from './components/Cases';
import Contato from './components/Contato';
// SubComponents
import NavBar from './components/subcomponents/navbar';
import Footer from './components/subcomponents/footer';
// CSS
import './assets/css/App.css';
function App() {
	return (
		<div className="App">
			<NavBar />
			<Cases />
			<Contato />
			<Footer />
		</div>
	);
}

export default App;
