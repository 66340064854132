import React from 'react';

const Footer = () => {
	return (
		<div>
			<footer>
				<span style={{color: '#C0272D'}}>Ninja Developer</span> &copy; {new Date().getFullYear()}
			</footer>
		</div>
	);
};

export default Footer;