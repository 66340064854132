import api from './api';

// eslint-disable-next-line
export default {
    enviarContato: async (data) => {
        try {
            const response = await api.post(`/enviar-contato`, data);
            return response.data;
        } catch(e){
            return false;
        }
    }
};