import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Lightbox from './subcomponents/lightbox';

const Cases = () => {
    const [lightboxImage, setLightboxImage] = useState('');
    const [lightboxLabel, setLightboxLabel] = useState('');

    const sites = [
        {  
            title: 'Autêntica Laudos',
            subtitle: 'Site',
            image: 'autentica_site.jpg', 
            url: 'http://www.autenticalaudos.com.br'
        },
        {  
            title: 'AutoComunicar',
            subtitle: 'Site',
            image: 'autocomunicar_site.jpg', 
            url: 'https://www.autocomunicar.com.br'
        },
        {  
            title: 'AutoRenave',
            subtitle: 'Site',
            image: 'autorenave_site.jpg', 
            url: 'https://www.autorenave.com.br'
        },
        {  
            title: 'CBRAV',
            subtitle: 'Site',
            image: 'cbrav_site.jpg', 
            url: 'https://cbrav.ninjadeveloper.com.br'
        },
        {  
            title: 'CobreMais',
            subtitle: 'Site',
            image: 'cobremais_site.jpg?v=2', 
            url: 'https://www.cobremais.com.br'
        },
        {  
            title: 'Curriculum Generator',
            subtitle: 'Site',
            image: 'cv_site.jpg', 
            url: 'http://projects.samuelcarrara.com/cvgenerator'
        },
        {  
            title: 'Drop me a link',
            subtitle: 'Site',
            image: 'dropmealink_site.jpg', 
            url: 'https://dropmea.link/'
        },
        {  
            title: 'Estado Placa',
            subtitle: 'Site',
            image: 'estadoplaca_site.jpg', 
            url: 'http://projects.samuelcarrara.com/estadoplaca/'
        },
        {  
            title: 'Loja Cred Auto',
            subtitle: 'Site',
            image: 'loja_site.jpg', 
            url: 'https://www.lojacredauto.com.br'
        },
        {  
            title: 'QR Code Generator',
            subtitle: 'Site',
            image: 'qrcode_site.jpg', 
            url: 'https://qr-code.digital/'
        },
        {  
            title: 'Plano Bíblico',
            subtitle: 'Site',
            image: 'leitura_site.jpg', 
            url: 'http://projects.samuelcarrara.com/planobiblico/'
        },
        {  
            title: 'Semeando Esperança',
            subtitle: 'Site',
            image: 'semeando_site.jpg', 
            url: 'http://www.semeandoesperanca.org'
        },
        {  
            title: 'Valix',
            subtitle: 'Site',
            image: 'valix_site.jpg', 
            url: 'https://www.valix.com.br'
        },
        {  
            title: 'Xpertia',
            subtitle: 'Site',
            image: 'xpertia_site.jpg', 
            url: 'https://www.xpertia.com.br'
        },
        {  
            title: 'XP Laudos',
            subtitle: 'Site',
            image: 'xplaudos_site.jpg', 
            url: 'https://www.xplaudos.com.br'
        },
    ];

    const apps = [
        {  
            title: 'CredLaudo',
            subtitle: 'Aplicativo',
            image: 'laudo_app.jpg', 
            url: 'https://itunes.apple.com/us/app/cred-laudo-mobile/id1158866612?l=pt&ls=1&mt=8'
        },
        {  
            title: 'CredManager',
            subtitle: 'Aplicativo',
            image: 'manager_app.jpg', 
            url: 'https://play.google.com/store/apps/details?id=com.credmanager.app'
        },
        {  
            title: 'PlanoLeitura',
            subtitle: 'Aplicativo',
            image: 'plano_app.jpg', 
            url: 'https://apps.apple.com/us/app/planoleitura/id1562801970'
        },
        {  
            title: 'RedeCredAuto',
            subtitle: 'Aplicativo',
            image: 'rca_app.jpg', 
            url: 'https://play.google.com/store/apps/details?id=com.redecredauto.consultas'
        },
    ];

    const designs = [
        {  
            title: 'Adabá - Brincos & CIA',
            subtitle: 'Identidade Visual',
            image: 'adaba.jpg'
        },
        {  
            title: 'AutoComunicar',
            subtitle: 'Identidade Visual',
            image: 'autocomunicar.jpg'
        },
        {  
            title: 'AutoRenave',
            subtitle: 'Identidade Visual',
            image: 'autorenave.jpg'
        },
        {  
            title: 'Celebrando a Recuperação',
            subtitle: 'Identidade Visual',
            image: 'celebrando.jpg'
        },
        {  
            title: 'Centro Pedagógico',
            subtitle: 'Identidade Visual',
            image: 'sandraribeiro.jpg'
        },
        {  
            title: 'CredAlerta',
            subtitle: 'Identidade Visual',
            image: 'alerta.jpg'
        },
        {  
            title: 'Crovato Advocacia',
            subtitle: 'Identidade Visual',
            image: 'crovato.jpg'
        },
        {  
            title: 'DNA - Nova Vida',
            subtitle: 'Identidade Visual',
            image: 'dna.jpg'
        },
        {  
            title: 'Equilibrium',
            subtitle: 'Identidade Visual',
            image: 'equilibrium.jpg'
        },
        {  
            title: 'Feijoada do Josa',
            subtitle: 'Identidade Visual',
            image: 'feijoada.jpg'
        },
        {  
            title: 'iKofr',
            subtitle: 'Identidade Visual',
            image: 'ikofr.jpg'
        },
        {  
            title: 'Ilza Salgados & Doces',
            subtitle: 'Identidade Visual',
            image: 'ilza.jpg'
        },
        {  
            title: 'LJ - Segurança e Manutenções',
            subtitle: 'Identidade Visual',
            image: 'lj.jpg'
        },
        {  
            title: 'Loja Cred Auto',
            subtitle: 'Identidade Visual',
            image: 'lojacred.jpg'
        },
        {  
            title: 'Maxima Isenções',
            subtitle: 'Identidade Visual',
            image: 'maxima.jpg'
        },
        {  
            title: 'Mensagens de Servo',
            subtitle: 'Identidade Visual',
            image: 'servo.jpg'
        },
        {  
            title: 'Projeto Semeando Esperança',
            subtitle: 'Identidade Visual',
            image: 'semeando.jpg'
        },
        {  
            title: 'RCA Group',
            subtitle: 'Identidade Visual',
            image: 'rca-group.jpg'
        },
        {  
            title: 'Reis & Principes',
            subtitle: 'Identidade Visual',
            image: 'reis_e_principes.jpg'
        },
        {  
            title: 'Ribz Comunicação',
            subtitle: 'Identidade Visual',
            image: 'ribz.jpg'
        },
        {  
            title: 'Transformação Online',
            subtitle: 'Identidade Visual',
            image: 'transformaonline.jpg'
        },
        {  
            title: 'Transformando JSA',
            subtitle: 'Identidade Visual',
            image: 'transformando.jpg'
        },
        {  
            title: 'Texto & Revisão',
            subtitle: 'Identidade Visual',
            image: 'textoerevisao.jpg'
        },
        {  
            title: 'XP Laudos',
            subtitle: 'Identidade Visual',
            image: 'xplaudos.jpg'
        },
        
    ];

	return (
        <Container style={{ marginTop: 40, marginBottom: 40 }}>
            <Lightbox image={lightboxImage} label={lightboxLabel} closeLightbox={setLightboxImage} />
            <div className="text-left" id="sites">
                <h4 style={{color: '#C0272D'}}>Sites</h4>
            </div>
            <Row>
                {
                    sites.map((row, index) => 
                        <Col md={4} lg={3} xs={6} key={index}>
                            <a href={row.url} target="_blank" rel="noreferrer" className="portfolio-item">
                                <div className="portfolio-thumb">
                                    <img 
                                        src={`https://projects.samuelcarrara.com/ninja/cases/${row.image}`} 
                                        alt={row.title} 
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="portfolio-info text-left">
                                    <h3 
                                        style={{
                                            fontSize: 14, 
                                            fontWeight: '600', 
                                            marginBottom: 10
                                        }}
                                    >
                                        {row.title}
                                    </h3>
                                </div>
                            </a>
                        </Col>
                    )
                }
            </Row>
            <div className="text-left" id="aplicativos">
                <h4 style={{color: '#C0272D'}}>Aplicativos</h4>
            </div>
            <Row>
                {
                    apps.map((row, index) => 
                        <Col md={4} lg={3} xs={6} key={index}>
                            <a href={row.url} target="_blank" rel="noreferrer" className="portfolio-item">
                                <div className="portfolio-thumb">
                                    <img 
                                        src={`https://projects.samuelcarrara.com/ninja/cases/${row.image}`} 
                                        alt={row.title} 
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="portfolio-info text-left">
                                    <h3 
                                        style={{
                                            fontSize: 14, 
                                            fontWeight: '600', 
                                            marginBottom: 10
                                        }}
                                    >
                                        {row.title}
                                    </h3>
                                </div>
                            </a>
                        </Col>
                    )
                }
            </Row>
            <div className="text-left" id="design">
                <h4 style={{color: '#C0272D'}}>Design</h4>
            </div>
            <Row>
                {
                    designs.map((row, index) => 
                        <Col md={4} lg={3} xs={6} key={index}>
                            <div className="portfolio-item portfolio-link"
                                onClick={() => {
                                    setLightboxImage(`https://projects.samuelcarrara.com/ninja/cases/${row.image}`);
                                    setLightboxLabel(row.title);
                                }}
                            >
                                <div className="portfolio-thumb">
                                    <img 
                                        src={`https://projects.samuelcarrara.com/ninja/cases/${row.image}`} 
                                        alt={row.title} 
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="portfolio-info text-left">
                                    <h3 
                                        style={{
                                            fontSize: 14, 
                                            fontWeight: '600', 
                                            marginBottom: 10
                                        }}
                                    >
                                        {row.title}
                                    </h3>
                                </div>
                            </div>
                        </Col>
                    )
                }
            </Row>
        </Container>
	);
};

export default Cases;