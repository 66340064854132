// Library
import React from 'react';
import ReactDOM from 'react-dom';
import { 
	transitions, positions, Provider as AlertProvider 
} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Component
import App from './App';

const options = {
	// you can also just use 'bottom center'
	position: positions.MIDDLE,
	timeout: 5000,
	offset: '30px',
	zIndex: '100000',
	// you can also just use 'scale'
	transition: transitions.SCALE
};


ReactDOM.render(
	<AlertProvider template={AlertTemplate} {...options}>
		<App />
	</AlertProvider>,
	document.getElementById('root')
);
