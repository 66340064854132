import React, { useState, useEffect } from 'react';
import { 
    Container, Row, Col, Input
} from 'reactstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { useAlert, types } from 'react-alert';
import emailValidator from 'email-validator';

import api from '../service/ApiHandler';

const Contato = () => {
    const alert = useAlert();
    const [recaptchaRef, setRecaptchaRef] = useState(null);

    const [assunto, setAssunto] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [loader, setLoader] = useState(false);

    const changeCaptcha = (c) => setCaptcha(c);

    const enviarMensagem = async () => {
        alert.removeAll();
        if(!assunto) alert.show('Informe o assunto', { type: types.ERROR });
        else if(!nome) alert.show('Informe seu nome', { type: types.ERROR });
        else if(!email) alert.show('Informe seu e-mail', { type: types.ERROR });
        else if(!emailValidator.validate(email)) alert.show('E-mail invalido', { type: types.ERROR });
        else if(!mensagem) alert.show('Preencha a mensagem', { type: types.ERROR });
        else if(!captcha) alert.show('Prove que não é um robô', { type: types.ERROR });
        else {
            setLoader(true);
            const response = await api.enviarContato({
                assunto, nome, email, mensagem, captcha, site: 'ninja'
            });
            if(!response){
                recaptchaRef.reset();
                setCaptcha('');
                alert.show('Erro de conexão :(', { type: types.ERROR });
                setLoader(false);
            } else if(response.error){
                setCaptcha('');
                recaptchaRef.reset();
                alert.show(response.error, { type: types.ERROR });
                setLoader(false);
            } else if(response.success){
                setCaptcha('');
                recaptchaRef.reset();
                alert.show(response.success, { type: types.SUCCESS });
                setAssunto('');
                setNome('');
                setEmail('');
                setMensagem('');
                setCaptcha('');
                setLoader(false);
            } else {
                setCaptcha('');
                recaptchaRef.reset();
                alert.show('Erro de conexão :(', { type: types.ERROR });
                setLoader(false);
            }
        }
    };

    useEffect(() => setLoader(false), []);

	return (
		<Container id="contato">
            { loader ? <div className="loader"></div> : <></> }
            <div className="text-left" id="contato">
                <h4 style={{color: '#C0272D'}}>Entre em Contato</h4>
            </div>
            <br />
            <Row>
                <Col className="text-left" md={4}>
                    <Input 
                        name="assunto" 
                        placeholder="Assunto"
                        value={assunto}
                        onChange={(e) => setAssunto(e.target.value)}
                    />
                    <Input 
                        name="nome" 
                        placeholder="Nome" 
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                    <Input 
                        name="email" 
                        placeholder="E-mail" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Col>
                <Col className="text-right" md={8}>
                    <textarea 
                        name="mensagem" 
                        className="form-control" 
                        placeholder="Mensagem"  
                        value={mensagem}
                        onChange={(e) => setMensagem(e.target.value)}
                    />
                </Col>
                <Col xs={6}>
                    <ReCAPTCHA
                        ref={(ref) => setRecaptchaRef(ref)}
                        sitekey="6Lfb1YQaAAAAAE9_bw-dVhcipQO2Bb6h4K1z5KSr"
                        onChange={changeCaptcha}
                    />
                    <br />
                </Col>
                <Col xs={6} className="text-right">
                    <button type="button" className="btn btn-success" onClick={enviarMensagem}>
                        Enviar
                    </button>
                </Col>
            </Row>
		</Container>
	);
};

export default Contato;